import gallery1 from "../assets/pictures/gallery/gallery18.webp";
import ReactPlayer from "react-player/lazy";
import gallery2 from "../assets/pictures/gallery/gallery20.webp";
import gallery3 from "../assets/pictures/gallery/gallery22.webp";
import gallery4 from "../assets/pictures/gallery/gallery8.webp";
import gallery5 from "../assets/pictures/gallery/gallery9.webp";
import gallery6 from "../assets/pictures/gallery/gallery26.webp";
import gallery7 from "../assets/pictures/gallery/gallery1.webp";
import gallery8 from "../assets/pictures/gallery/gallery7.webp";
import gallery9 from "../assets/pictures/gallery/gallery13.webp";
import gallery10 from "../assets/pictures/gallery/gallery11.webp";
import gallery11 from "../assets/pictures/gallery/gallery12.webp";
import gallery12 from "../assets/pictures/gallery/gallery2.webp";
import gallery13 from "../assets/pictures/gallery/gallery15.webp";
import gallery14 from "../assets/pictures/gallery/gallery6.webp";
import gallery15 from "../assets/pictures/gallery/gallery3.webp";
import DesktopWa from "../components/DesktopWa";
import { cls } from "../components/Utility";
import Call from "../components/Call";

window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
    } else {
      reveals[i].classList.remove("animated", "zoom-in");
    }
  }
}
const vedioData = [
  "https://youtu.be/Uz-TK5Gj0so?si=BjZwBwHJbQfoOBCy",
  "https://youtu.be/aEbhfncbO-4?si=aFMfXFb2XODm0klv",
  "https://youtu.be/r6Vaysd6CoU?si=IAKf2BH9bul7Cd0i",
  "https://youtu.be/U6PiYt222JM?si=S2AC5da4M1-XRBVM",
  "https://youtu.be/UUqyg9P8XQI?si=9Uyu3dyo-3LfW0sB",
  "https://youtu.be/KJK5ADC3BZs?si=D8BqqfG2GaVTThoV ",
];
export default function Gallery() {
  return (
    <>
      <div className="bg-[#f5f5f5] pb-[30px]">
        <section className="hero1">
          <div className="hero1-content">
            <p className="h1-textAnimation mb-[30px] xl:text-[60px]  text-[40px] font-bold font-[season]">
              A Symphony of Images, Welcome to Our Gallery
            </p>
            <p className="text-[18px] font-[poppins] font-semibold ">
              Immerse yourself in a captivating collection where every image
              tells a unique story. Experience the harmony of visual artistry.
            </p>
          </div>
        </section>

        <p className="mt-[30px] mb-[50px] text-[14px] font-[poppins] text-[#A4a4a4] text-center pl-[15px] pr-[15px] xl:pl-[200px] xl:pr-[200px]">
          Step into a vibrant world where every photo resonates with life and
          color. Our gallery showcases a diverse array of categories, from
          breathtaking landscapes to intimate portraits, and dynamic urban
          scenes to serene nature shots. Each image is a testament to the beauty
          and diversity of our world, capturing moments that inspire and evoke
          emotion.
        </p>
        <div className="xl:flex xl:flex-row xl:justify-center xl:items-center gap-[0.8px] hidden">
          <div className="flex flex-col gap-[0.8px] ">
            <img
              src={gallery1}
              alt="gallery1"
              className="w-[300px] h-[300px] object-cover grid-item animated zoom-in"
            />
            <img
              src={gallery2}
              alt="gallery1"
              className="w-[300px] h-[400px] object-cover grid-item animated zoom-in"
            />
            <img
              src={gallery3}
              alt="gallery1"
              className="w-[300px] h-[300px] object-cover grid-item animated zoom-in"
            />
          </div>

          <div className="flex flex-col gap-[1px] ">
            <img
              src={gallery4}
              alt="gallery1"
              className="w-[300px] h-[400px] object-cover grid-item animated zoom-in"
            />
            <img
              src={gallery5}
              alt="gallery1"
              className="w-[300px] h-[200px] object-cover grid-item animated zoom-in"
            />
            <img
              src={gallery6}
              alt="gallery1"
              className="w-[300px] h-[400px] object-cover grid-item animated zoom-in"
            />
          </div>

          <div className="flex flex-col gap-[1px]">
            <img
              src={gallery7}
              alt="gallery1"
              className="w-[300px] h-[300px] object-cover grid-item animated zoom-in"
            />
            <img
              src={gallery8}
              alt="gallery1"
              className="w-[300px] h-[400px] object-cover grid-item animated zoom-in"
            />
            <img
              src={gallery9}
              alt="gallery1"
              className="w-[300px] h-[300px] object-cover grid-item animated zoom-in"
            />
          </div>

          <div className="flex flex-col gap-[1px] ">
            <img
              src={gallery10}
              alt="gallery1"
              className="w-[300px] h-[400px] object-cover grid-item animated zoom-in"
            />
            <img
              src={gallery11}
              alt="gallery1"
              className="w-[300px] h-[400px] object-cover grid-item animated zoom-in"
            />
            <img
              src={gallery12}
              alt="gallery1"
              className="w-[300px] h-[200px] object-cover grid-item animated zoom-in"
            />
          </div>

          <div className="flex flex-col gap-[1px] ">
            <img
              src={gallery13}
              alt="gallery1"
              className="w-[300px] h-[300px] object-cover grid-item animated zoom-in"
            />
            <img
              src={gallery14}
              alt="gallery1"
              className="w-[300px] h-[400px] object-cover grid-item animated zoom-in"
            />
            <img
              src={gallery15}
              alt="gallery1"
              className="w-[300px] h-[300px] object-cover grid-item animated zoom-in"
            />
          </div>
        </div>

        <div className="mt-[30px] xl:hidden grid grid-cols-2 gap-[0.8px]">
          <img
            src={gallery1}
            alt="gallery1"
            className="w-full h-full object-cover grid-item animated zoom-in"
          />
          <img
            src={gallery2}
            alt="gallery1"
            className="w-full h-full object-cover grid-item animated zoom-in"
          />
          <img
            src={gallery3}
            alt="gallery1"
            className="w-full h-full object-cover grid-item animated zoom-in"
          />
          <img
            src={gallery4}
            alt="gallery1"
            className="w-full h-full object-cover grid-item animated zoom-in"
          />

          <img
            src={gallery5}
            alt="gallery1"
            className="w-full h-full object-cover grid-item animated zoom-in"
          />
          <img
            src={gallery6}
            alt="gallery1"
            className="w-full h-full object-cover grid-item animated zoom-in"
          />

          <img
            src={gallery7}
            alt="gallery1"
            className="w-full h-full object-cover grid-item animated zoom-in"
          />
          <img
            src={gallery8}
            alt="gallery1"
            className="w-full h-full object-cover grid-item animated zoom-in"
          />

          <img
            src={gallery9}
            alt="gallery1"
            className="w-full h-full object-cover grid-item animated zoom-in"
          />

          <img
            src={gallery10}
            alt="gallery1"
            className="w-full h-full object-cover grid-item animated zoom-in"
          />
          <img
            src={gallery11}
            alt="gallery1"
            className="w-full h-full object-cover grid-item animated zoom-in"
          />

          <img
            src={gallery12}
            alt="gallery1"
            className="w-full h-full object-cover grid-item animated zoom-in"
          />
          <img
            src={gallery13}
            alt="gallery1"
            className="w-full h-full object-cover grid-item animated zoom-in"
          />

          <img
            src={gallery14}
            alt="gallery1"
            className="w-full h-full object-cover grid-item animated zoom-in"
          />
         
        </div>

        <p className="text-[30px] xl:text-[40px] font-[season] font-bold mt-[50px] xl:pl-[150px] xl:pr-[150px] pl-[15px] pr-[15px] text-center">
          Relive the Moments In Motion
        </p>

        <div className="hidden xl:flex xl:justify-center xl:items-center mt-[40px]">
          <div
            className={cls(
              "hidden",
              "xl:pl-[25px] xl:pr-[25px] xl:grid xl:grid-cols-2 xl:gap-[2px]"
            )}
          >
            {vedioData.map((item, index) => (
              <div
                className="rounded-2xl overflow-hidden object-cover"
                key={index}
              >
                <ReactPlayer
                  url={item}
                  width={700}
                  height={400}
                  playing={false}
                  controls={true}
                  volume={1}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div
            className={cls(
              "pl-[15px] pr-[15px] grid grid-cols-1 gap-[20px] mt-[30px] ",
              "xl:hidden"
            )}
          >
            {vedioData.map((item, index) => (
              <div className="rounded-2xl overflow-hidden" key={index}>
                <ReactPlayer
                  url={item}
                  volume={1}
                  width={350}
                  height={200}
                  playing={false}
                  controls={true}
                />
              </div>
            ))}
          </div>{" "}
        </div>
        <div className="mt-[20px] flex flex-col items-center gap-[10px] xl:hidden font-bold text-[25px] mb-[20px] pl-[15px] pr-[15px]">
          <p>Call for free consultation at</p>
          <div>
            <Call phoneNumber="+91 9739003636" />
          </div>
        </div>
      </div>

      <div className="chat-icon">
        <DesktopWa />
      </div>
    </>
  );
}
