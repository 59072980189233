import wedding from "../assets/pictures/services/wedding1.webp";
import prewedding from "../assets/pictures/services/prewedding1.webp";
import engagement from "../assets/pictures/services/engagement1 (1).webp";
import corporate from "../assets/pictures/services/corporateevents (2) (1).webp";
import decor1 from "../assets/pictures/services/decor.webp";

import decor from "../assets/pictures/services/decoration1.png";
import photo from "../assets/pictures/services/photography1.png";
import vedioicon from "../assets/pictures/services/videography1.png";
import mehendi from "../assets/pictures/services/mehendi1.png";
import makeup from "../assets/pictures/services/makeup1.png";
import venue from "../assets/pictures/services/venue1.png";
import invite from "../assets/pictures/services/invite1.png";
import entertainment from "../assets/pictures/services/entertainment1.png";
import maternity from "../assets/pictures/services/maternityshoot1.png";
import babyshower from "../assets/pictures/services/babyshower1.png";
import event from "../assets/pictures/services/eventicon.png";
import portfolio from "../assets/pictures/services/portfolioshooticon.png";
import ecommerce from "../assets/pictures/services/ecommerceicon.png";
import birthday from "../assets/pictures/services/birthdayicon.png";
import newborn from "../assets/pictures/services/newborn1.png";
import cradle from "../assets/pictures/services/cradaleicon.png";
import house from "../assets/pictures/services/housewarmingiocn.png";
import postwedding from "../assets/pictures/services/postweddingicon.png";
import naming from "../assets/pictures/services/portfolioshooticon.png";
import upanayana from "../assets/pictures/services/upanayamicon.png";
import shastipurthi from "../assets/pictures/services/shastipurthi1.png";
import frame from "../assets/pictures/services/photoframe1.png";
import album from "../assets/pictures/services/premiumalbum1.png";
import potraits from "../assets/pictures/services/potrait1.png";
import albumdesigning from "../assets/pictures/services/albumdesigning1.png";
import candid from "../assets/pictures/services/candid1.png";
import concept from "../assets/pictures/services/concept1.png";
import traditional from "../assets/pictures/services/traditional1.png";
import drone from "../assets/pictures/services/dron1.png";
import professionalphoto from "../assets/pictures/services/professionalphotography1.png";
import professionalvideo from "../assets/pictures/services/professionalvideography1.png";
import religious from "../assets/pictures/services/religiousphotoshoot1.png";
import baptism from "../assets/pictures/services/baptism1.png";
import eventlive from "../assets/pictures/services/livestreaming1.png";
import seminar from "../assets/pictures/services/seminar1.png";
import tradeshow from "../assets/pictures/services/tradeshow1.png";
import expo from "../assets/pictures/services/expo1.png";
import graduation from "../assets/pictures/services/graduation1.png";
import concert from "../assets/pictures/services/concert1.png";
import festival from "../assets/pictures/services/festival1.png";
import sports from "../assets/pictures/services/sports1.png";
import gala from "../assets/pictures/services/gala1.png";
import charity from "../assets/pictures/services/charity1.png";
import fashionshow from "../assets/pictures/services/fashionshow1 (2).png";
import clubevent from "../assets/pictures/services/clubevent1.png";
import { Link } from "react-router-dom";
import DesktopWa from "../components/DesktopWa";
import Call from "../components/Call";

window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
    } else {
      reveals[i].classList.remove("animated", "zoom-in");
    }
  }
}

const servicesData = [
  {
    src: decor,
    title: "Decoration",
  },
  {
    src: photo,
    title: "Photography",
  },

  {
    src: vedioicon,
    title: "Videography",
  },

  {
    src: mehendi,
    title: "mehendi",
  },

  {
    src: makeup,
    title: "makeup & hairstyling",
  },

  {
    src: venue,
    title: "venues",
  },

  {
    src: invite,
    title: "invites",
  },

  {
    src: entertainment,
    title: "entertainment",
  },

  {
    src: maternity,
    title: "maternity photoshoot",
  },

  {
    src: babyshower,
    title: "baby shower photoshoot",
  },

  {
    src: event,
    title: "Event Photography",
  },

  {
    src: portfolio,
    title: "Portfolio Shoot",
  },

  {
    src: ecommerce,
    title: "Product & E-commerce shoot",
  },

  {
    src: birthday,
    title: "birthday shoot",
  },

  {
    src: newborn,
    title: "newborn shoot",
  },
  {
    src: cradle,
    title: "Cradle ceremony shoot",
  },
  {
    src: house,
    title: "house warming photoshoot",
  },
  {
    src: postwedding,
    title: "post-wedding photoshoot",
  },
  {
    src: naming,
    title: "naming ceremony",
  },
  {
    src: upanayana,
    title: "upanayana shoot",
  },

  {
    src: shastipurthi,
    title: "shastipurthi shoot",
  },
  {
    src: frame,
    title: "photo frames",
  },

  {
    src: album,
    title: "premium albums",
  },
  {
    src: potraits,
    title: "potraits",
  },
  {
    src: albumdesigning,
    title: "album designing & printing",
  },
  {
    src: candid,
    title: "candid shoots & videos",
  },
  {
    src: concept,
    title: "concept shoots & videos",
  },
  {
    src: traditional,
    title: "traditional shoots & videos",
  },

  {
    src: drone,
    title: "drone photography",
  },
  {
    src: professionalphoto,
    title: "professional photography",
  },

  {
    src: professionalvideo,
    title: "professional videography",
  },
  {
    src: religious,
    title: "Religious ceremony photography",
  },

  {
    src: baptism,
    title: "baptism photography",
  },
  {
    src: eventlive,
    title: "event live streaming",
  },

  {
    src: seminar,
    title: "seminar photography",
  },

  {
    src: tradeshow,
    title: "trade show photography",
  },
  {
    src: expo,
    title: "expo photography",
  },

  {
    src: graduation,
    title: "graduation photography",
  },
  {
    src: concert,
    title: "concert photography",
  },

  {
    src: festival,
    title: "festival photoshoot",
  },

  {
    src: sports,
    title: "sports events photography",
  },
  {
    src: gala,
    title: "gala photoshoot",
  },
  {
    src: charity,
    title: "charity event photoshoot",
  },
  {
    src: fashionshow,
    title: "fashion show photoshoot",
  },
  {
    src: clubevent,
    title: "club event shoots",
  },
];

const cardData = [
  {
    src: wedding,
    title: "Wedding",
    description1: `Experience exceptional quality and artistry with our wedding
  photography, where every moment is captured beautifully. We
  provide stunning visuals tailored to your budget, ensuring you
  get the best value. Trust us to deliver unforgettable memories
  with unparalleled dedication and professionalism.`,
    description2: ` We take pride in delivering top-notch wedding photography that
  reflects your unique love story. Our services are tailored to
  meet your budget without compromising on quality, ensuring each
  cherished moment is beautifully documented.`,
  },

  {
    src: prewedding,
    title: "PreWedding",
    description1: ` Embark on your journey together with enchanting pre-wedding
  photography. Capturing the essence of your love story before the
  big day, our expert photographers create timeless memories filled
  with romance and anticipation. Trust us to turn your pre-wedding
  moments into captivating visuals that you'll cherish forever.`,
    description2: ` Preserve the joy and anticipation of your pre-wedding days with
  our professional photography services. From romantic sunsets to
  playful moments, we'll create a visual narrative that beautifully
  captures the essence of your relationship, leaving you with
  cherished memories to last a lifetime.`,
  },
  {
    src: engagement,
    title: "Engagement",
    description1: `Celebrate the milestone of your engagement with our exquisite
  photography services. From the tender moment of the proposal to
  the joyous celebration with loved ones, our skilled
  photographers will capture every emotion and detail, preserving
  the magic of this special time in your life.`,
    description2: ` With a keen eye for storytelling and a passion for capturing
  authentic moments, we'll transform your engagement into a visual
  journey that reflects the love and commitment you share. Whether
  you envision a romantic outdoor shoot or an intimate indoor
  session, our personalized approach ensures that your engagement
  photos are as unique as your love story. Trust us to turn your
  engagement into a timeless tale that you'll treasure for years
  to come.`,
  },
  {
    src: corporate,
    title: "Corporate Events",
    description1: ` Elevate your corporate events with our professional photography
  services. From conferences and seminars to product launches and
  team-building activities, our expert photographers specialize in
  capturing the essence and energy of your corporate gatherings.`,
    description2: ` With a keen eye for detail and a commitment to excellence, we'll
  document every moment and milestone, ensuring that your corporate
  event is immortalized in stunning imagery. Whether you're looking
  to showcase your brand's success or capture candid interactions
  among colleagues, trust us to deliver high-quality photos that
  reflect the professionalism and vibrancy of your event.`,
  },
  {
    src: decor1,
    title: "Decor",
    description1: ` Transform any space into a captivating setting with our
  exceptional event decor services. From elegant weddings and
  glamorous galas to corporate functions and themed parties, we
  specialize in creating immersive environments that leave a
  lasting impression.`,
    description2: ` With meticulous attention to detail and a passion for
  creativity, our team of designers will bring your vision to
  life, combining exquisite floral arrangements, stylish
  furnishings, and innovative lighting to enhance the ambiance of
  your event. Whether you desire timeless elegance or contemporary
  chic, trust us to deliver breathtaking decor that sets the
  perfect stage for your special occasion.`,
  },
];

export default function Services() {
  return (
    <>
      <div className="bg-[#f5f5f5] pb-[30px]">
        <div className="xl:block hidden">
          {" "}
          <div className="hero">
            <div className="flex flex-col gap-[20px] w-[500px] absolute left-[10px] ">
             <p className="text-[60px] font-[season] font-bold text-white"> Dreaming of Stunning Wedding Photos?</p>
             <p className=" text-[18px] font-bold font-[poppins] text-white "> Capture your special day with exquisite photography, all within
             your budget.</p>
            </div>
          
             
           
          </div>
        </div>

        <div className="xl:hidden block">
          {" "}
          <div className="hero">
            <div className="flex flex-col font-bold text-white absolute left-0 w-[300px]">
              <p className="text-[40px] font-[season]">
                Dreaming of Stunning Wedding Photos?
              </p>
              <p className=" text-[18px] mt-[30px] font-[poppins]">
                Capture your special day with exquisite photography, all within
                your budget.
              </p>
            </div>
          </div>
        </div>

        {/* this is for the mobile view */}
        <div className="mt-[30px] flex flex-col gap-[20px] xl:hidden pl-[15px] pr-[15px]">
          {cardData.map((item, index) => (
            <div key={index} className="flex flex-col items-center gap-[20px]">
              <img
                src={item.src}
                alt="wedding photos"
                className="w-[250px] h-[300px] rounded-full object-cover border-[15px] border-white"
              />
              <p className="text-[40px] font-[season] font-bold">{item.title}</p>
              <p className="text-[14px] font-[poppins] text-[#A4A4A4] self-start">{item.description1}</p>
              <p className="text-[14px] font-[poppins] text-[#A4A4A4] self-start">{item.description2}</p>
              <Link to="/contact">
              {" "}
              <button className="hover:rounded-[25px] font-[poppins] text-center text-white font-semibold w-[200px] p-[15px] rounded-[20px] bg-[black] text-[14px] ">
                Book Us Now
              </button>
            </Link>
            </div>
          ))}
        </div>

        <div className="xl:mt-[30px]">
          <div className="xl:flex xl:flex-row xl:justify-center hidden gap-[100px] pl-[25px] pr-[25px]">
            <div className="flex">
              <img
                src={wedding}
                alt="grid wedding services data"
                className="w-[400px] h-[500px] rounded-full object-cover border-white border-[15px]"
              />
            </div>

            <div className="flex flex-col w-[600px] mt-[50px] ">
              <p className="text-[40px] font-bold font-[season]">Wedding</p>
              <p className="text-[14px] text-[#A4a4a4] mt-[30px] font-[poppins]">
                Experience exceptional quality and artistry with our wedding
                photography, where every moment is captured beautifully. We
                provide stunning visuals tailored to your budget, ensuring you
                get the best value. Trust us to deliver unforgettable memories
                with unparalleled dedication and professionalism.
                <br />
                <br />
                We take pride in delivering top-notch wedding photography that
                reflects your unique love story. Our services are tailored to
                meet your budget without compromising on quality, ensuring each
                cherished moment is beautifully documented.
              </p>
              <Link to="/contact">
                {" "}
                <button className="hover:rounded-[25px] font-[poppins] text-[14px] mt-[30px] text-center text-white font-semibold w-[200px] p-[15px] rounded-[20px] bg-[black]  ">
                  Book Us Now
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="xl:flex xl:flex-row xl:justify-center hidden gap-[100px] pl-[25px] pr-[25px] mt-[20px]">
          <div className="flex flex-col w-[600px] mt-[50px] ">
            <p className="text-[40px] font-bold font-[season]">PreWedding</p>
            <p className="text-[14px] text-[#A4a4a4] font-[poppins] mt-[30px]">
              Embark on your journey together with enchanting pre-wedding
              photography. Capturing the essence of your love story before the
              big day, our expert photographers create timeless memories filled
              with romance and anticipation. Trust us to turn your pre-wedding
              moments into captivating visuals that you'll cherish forever.
              <br />
              <br />
              Preserve the joy and anticipation of your pre-wedding days with
              our professional photography services. From romantic sunsets to
              playful moments, we'll create a visual narrative that beautifully
              captures the essence of your relationship, leaving you with
              cherished memories to last a lifetime.
            </p>
            <Link to="/contact">
              {" "}
              <button className="hover:rounded-[25px] mt-[30px] text-center text-[14px] font-[poppins] text-white font-semibold w-[200px] p-[15px] rounded-[20px] bg-[black]  ">
                Book Us Now
              </button>
            </Link>
          </div>
          <div className="flex">
            <img
              src={prewedding}
              alt="engagement data"
              className="w-[400px] h-[500px] rounded-full object-cover border-white border-[15px]"
            />
          </div>
        </div>

        <div className="xl:mt-[20px]">
          <div className="xl:flex xl:flex-row xl:justify-center hidden gap-[100px] pl-[25px] pr-[25px]">
            <div className="flex">
              <img
                src={engagement}
                alt="corporate event data"
                className="w-[400px] h-[500px] rounded-full object-cover border-white border-[15px]"
              />
            </div>

            <div className="flex flex-col w-[600px] mt-[50px] ">
              <p className="text-[40px] font-[season] font-bold">Engagement</p>
              <p className="text-[14px] font-[poppins] text-[#A4a4a4] mt-[30px]">
                Celebrate the milestone of your engagement with our exquisite
                photography services. From the tender moment of the proposal to
                the joyous celebration with loved ones, our skilled
                photographers will capture every emotion and detail, preserving
                the magic of this special time in your life.
                <br />
                <br />
                With a keen eye for storytelling and a passion for capturing
                authentic moments, we'll transform your engagement into a visual
                journey that reflects the love and commitment you share. Whether
                you envision a romantic outdoor shoot or an intimate indoor
                session, our personalized approach ensures that your engagement
                photos are as unique as your love story. Trust us to turn your
                engagement into a timeless tale that you'll treasure for years
                to come.
              </p>
              <Link to="/contact">
                {" "}
                <button className="hover:rounded-[25px]  text-[14px] font-[poppins] mt-[30px] text-center text-white font-semibold w-[200px] p-[15px] rounded-[20px] bg-[black]  ">
                  Book Us Now
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="xl:flex xl:flex-row xl:justify-center hidden gap-[100px] pl-[25px] pr-[25px] mt-[20px]">
          <div className="flex flex-col w-[600px] mt-[50px] ">
            <p className="text-[40px] font-bold font-[season]">Corporate Events</p>
            <p className="text-[14px] text-[#A4a4a4] mt-[30px] font-[poppins]">
              Elevate your corporate events with our professional photography
              services. From conferences and seminars to product launches and
              team-building activities, our expert photographers specialize in
              capturing the essence and energy of your corporate gatherings.
              <br />
              <br />
              With a keen eye for detail and a commitment to excellence, we'll
              document every moment and milestone, ensuring that your corporate
              event is immortalized in stunning imagery. Whether you're looking
              to showcase your brand's success or capture candid interactions
              among colleagues, trust us to deliver high-quality photos that
              reflect the professionalism and vibrancy of your event.
            </p>
            <Link to="/contact">
              {" "}
              <button className="hover:rounded-[25px] font-[poppins] text-[14px] mt-[30px] text-center text-white font-semibold w-[200px] p-[15px] rounded-[20px] bg-[black]  ">
                Book Us Now
              </button>
            </Link>
          </div>
          <div className="flex">
            <img
              src={corporate}
              alt="decor data"
              className="w-[400px] h-[500px] rounded-full object-cover border-white border-[15px]"
            />
          </div>
        </div>

        <div className="xl:mt-[20px]">
          <div className="xl:flex xl:flex-row xl:justify-center hidden gap-[100px] pl-[25px] pr-[25px]">
            <div className="flex">
              <img
                src={decor1}
                alt="wedding data services"
                className="w-[400px] h-[500px] rounded-full object-cover border-white border-[15px]"
              />
            </div>

            <div className="flex flex-col w-[600px] mt-[50px] ">
              <p className="text-[40px] font-bold font-[season]">Decor</p>
              <p className="text-[14px] text-[#A4a4a4] mt-[30px] font-[poppins]">
                Transform any space into a captivating setting with our
                exceptional event decor services. From elegant weddings and
                glamorous galas to corporate functions and themed parties, we
                specialize in creating immersive environments that leave a
                lasting impression.
                <br />
                <br />
                With meticulous attention to detail and a passion for
                creativity, our team of designers will bring your vision to
                life, combining exquisite floral arrangements, stylish
                furnishings, and innovative lighting to enhance the ambiance of
                your event. Whether you desire timeless elegance or contemporary
                chic, trust us to deliver breathtaking decor that sets the
                perfect stage for your special occasion.
              </p>
              <Link to="/contact">
                {" "}
                <button className="hover:rounded-[25px] mt-[30px] text-center text-[14px] font-[poppins] text-white font-semibold w-[200px] p-[15px] rounded-[20px] bg-[black]  ">
                  Book Us Now
                </button>
              </Link>
            </div>
          </div>
        </div>

        <p className="mt-[40px] font-bold xl:text-[40px] text-[30px] font-[season] text-center pl-[15px] pr-[15px] xl:pl-[150px] xl:pr-[150px]">
          Explore the versatile events we provide ... 
        </p>
        <div className="xl:flex xl:justify-center xl:mt-[30px] xl:flex-row hidden">
          <div className="grid grid-cols-5 gap-[20px]">
            {servicesData.map((item, index) => (
              <Link to="/contact">
                {" "}
                <div
                  key={index}
                  className="flex flex-col grid-item animated zoom-in items-center gap-[20px] rounded-2xl shadow-lg bg-white w-[170px] h-[170px] p-[15px]"
                >
                  <img
                    src={item.src}
                    alt="services data"
                    className="w-[60px] h-[60px] object-cover"
                  />
                  <p className="font-bold text-[14px] capitalize">
                    {item.title}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>


        <div className="flex justify-center mt-[30px] xl:hidden pl-[15px] pr-[15px]">
          <div className="grid grid-cols-2 gap-[20px]">
            {servicesData.map((item, index) => (
              <Link to="/contact">
                {" "}
                <div
                  key={index}
                  className="flex flex-col grid-item animated zoom-in items-center gap-[20px] rounded-2xl shadow-lg bg-white w-full h-[170px] p-[15px]"
                >
                  <img
                    src={item.src}
                    alt="services data"
                    className="xl:w-[40px] xl:h-[40px] w-[60px] h-[60px] object-cover"
                  />
                  <p className="font-bold text-[14px] capitalize font-[poppins]">
                    {item.title}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="mt-[20px] flex flex-col items-center gap-[10px] xl:hidden font-bold text-[25px] mb-[20px] pl-[15px] pr-[15px]">
          <p>Call for free consultation at</p>
          <div>
            <Call phoneNumber="+91 9739003636" />
          </div>
        </div>
      </div>

      <div className="chat-icon">
        <DesktopWa />
      </div>
    </>
  );
}
