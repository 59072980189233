import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import grid1 from "../assets/pictures/home/grid1.webp";
import grid2 from "../assets/pictures/home/grid2.webp";
import grid3 from "../assets/pictures/home/grid3.webp";
import grid4 from "../assets/pictures/home/grid4.webp";
import grid5 from "../assets/pictures/home/grid5.webp";
import grid6 from "../assets/pictures/home/grid6.webp";
import grid7 from "../assets/pictures/home/grid7.webp";
import grid8 from "../assets/pictures/home/grid8.webp";
import grid9 from "../assets/pictures/home/grid9.webp";
import grid10 from "../assets/pictures/home/grid10.webp";
import grid11 from "../assets/pictures/home/grid11.webp";
import grid12 from "../assets/pictures/home/grid12.webp";
import grid13 from "../assets/pictures/home/grid13.webp";
import grid14 from "../assets/pictures/home/grid14.webp";
import grid15 from "../assets/pictures/home/grid15.webp";
import grid16 from "../assets/pictures/home/grid16.webp";
import { FaAward } from "react-icons/fa6";
import { MdMiscellaneousServices } from "react-icons/md";
import { MdWorkspacePremium } from "react-icons/md";
import { FaSackDollar } from "react-icons/fa6";
import { BiSolidHappyHeartEyes } from "react-icons/bi";
import g4 from "../assets/pictures/home/stanza2.webp";
import g5 from "../assets/pictures/home/stanza3.webp";
import g6 from "../assets/pictures/home/stanza4.webp";
import DesktopWa from "../components/DesktopWa";
import Call from "../components/Call";
import card1 from "../assets/pictures/home/card1 (2).webp";
import card2 from "../assets/pictures/home/card1.webp";
import testimonial1 from "../assets/pictures/home/testimonial1.webp";
import testimonial2 from "../assets/pictures/home/testimonial2.webp";

export default function Home() {
  const formRef = useRef(null);
  const [result, setResult] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const blogRef = useRef(null);

  // Function to handle the smooth scroll
  const scrollToBlog = () => {
    blogRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const [formData, setFormData] = useState({
    name: "",
    event: "",
    happeningDate: "",
    countryCode: "+91",
    phoneNumber: "",
    city: "",
    message: "",
  });
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      happeningDate: date,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    setShowMessage(true);

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    formDataToSend.append("access_key", "53b106b1-81fa-4d84-ab25-d99e62363861");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formDataToSend,
      });
      const data = await response.json();

      if (data.success) {
        setResult("Form Submitted Successfully");
        setFormData({
          name: "",
          event: "",
          countryCode: "+91",
          phoneNumber: "",
        });
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setResult("An error occurred. Please try again later.");
    }

    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  };

  const [count, setCount] = useState(0); // Initialize state for the counter

  useEffect(() => {
    const target = 1250;
    const duration = 2000; // Duration of the animation in milliseconds
    const interval = 10; // Interval time in milliseconds
    const increment = target / (duration / interval); // Calculate the increment value

    const counterInterval = setInterval(() => {
      setCount((prevCount) => {
        const newCount = prevCount + increment; // Update the count incrementally
        if (newCount >= target) {
          clearInterval(counterInterval); // Clear the interval when the target is reached
          return target;
        }
        return newCount;
      });
    }, interval);

    // Cleanup interval on component unmount
    return () => clearInterval(counterInterval);
  }, []); // Empty dependency array means this effect runs once on mount

  const [secondcount, setSecondCount] = useState(0); // Initialize state for the counter

  useEffect(() => {
    const target = 12;
    const duration = 2000; // Duration of the animation in milliseconds
    const interval = 10; // Interval time in milliseconds
    const increment = target / (duration / interval); // Calculate the increment value

    const counterInterval = setInterval(() => {
      setSecondCount((prevCount) => {
        const newCount = prevCount + increment; // Update the count incrementally
        if (newCount >= target) {
          clearInterval(counterInterval); // Clear the interval when the target is reached
          return target;
        }
        return newCount;
      });
    }, interval);

    // Cleanup interval on component unmount
    return () => clearInterval(counterInterval);
  }, []); // Empty dependency array means this effect runs once on mount

  const displayCardData = [
    {
      imagesrc: card1,
      title1: "Looking for",
      title: "wedding photography",
      description: `Capture your special day with professional flair and
                     affordability. Quality photography tailored to your budget,
                     preserving memories beautifully.`,
    },
    {
      imagesrc: card2,
      title1: "Looking for",
      title: "wedding decor",
      description: `Transform your special day with stunning, affordable decor. Elegant designs tailored to your budget, making your wedding truly unforgettable.`,
    },
  ];

  const displayStepsData = [
    {
      title: "Speak to us",
      description: " Give your requirement & preferences to your planner",
    },
    {
      title: "See the options",
      description: " Get a walkthrough of our designs, pricing & services",
    },
    {
      title: "Get custom package",
      description: "Receive a customised package based on your requirement",
    },
    {
      title: "Book us",
      description: "Pay a small fee to confirm once you are happy",
    },
    {
      title: "Relax",
      description:
        "Sit back & relax while we do all the planning & coordination",
    },
  ];

  const displayTestimonialData = [
    {
      imgsrc: testimonial1,
      description1: `Artisan Photography captured our wedding day perfectly with stunning, heartfelt photos.  `,
      description2: `Their professionalism and attention to detail are unmatched.We highly recommend them to any couple! `,
      name: "Neethu & Kiran",
    },
    {
      imgsrc: testimonial2,
      description1: `From start to finish, Artisan Photography was exceptional. 
      They captured every special moment beautifully and made us feel at ease. 
      The photos are stunning and exceeded all our expectations!`,
      description2: ``,
      name: "Srujitha & Vinay",
    },
  ];

  return (
    <>
      <div className="bg-[#f5f5f5] pb-[30px] ">
        <div className="xl:block hidden">
          <section ref={formRef} className="hero2">
            <div className="flex flex-col gap-[20px] w-[500px] absolute left-0 ">
             <p className="font-[season] text-[60px] font-extrabold text-white">Looking for Budget Wedding Photography ?</p> 
             <p className="text-[18px] font-[poppinsbold]  text-white"> Capture memories on a budget — Artisan photography made it simple!</p>
            </div>
            
            
            <div className="absolute right-[20px] w-[400px]">
              <div className="rounded-2xl xl:px-[20px] xl:py-[20px] px-[30px] py-[25px] bg-[#edecec] border-[1px] border-[#875239] mb-[30px] xl:w-full  ">
                <p className="text-[30px] font-bold font-[season] text-center mb-[15px]">
                  Connect With Us{" "}
                </p>
                <p className="text-[12px] mb-[25px]  text-center font-[poppins]">
                  Connect with us to gain expert insights and personalized
                  guidance tailored to your needs.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col gap-[20px]">
                    <input
                      type="text"
                      name="name"
                      autoComplete="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="rounded-xl font-[poppins] w-full p-[10px] outline-none placeholder:text-sky-950 text-[14px]"
                      placeholder="Name*"
                    />

                    <div className="flex gap-[20px]">
                      <label htmlFor="countryCode" className="sr-only">
                        Country Code:
                      </label>
                      <select
                        id="countryCode"
                        name="countryCode"
                        autoComplete="country code"
                        value={formData.countryCode}
                        onChange={handleChange}
                        className="rounded-xl p-[10px] outline-none placeholder:text-sky-950 text-[14px] w-[100px] font-[poppins]"
                      >
                        <option value="+91">+91</option>
                        <option value="+1">+1</option>
                        <option value="+44">+44</option>
                        <option value="+61">+61</option>
                        <option value="+86">+86</option>
                        <option value="+33">+33</option>
                        <option value="+49">+49</option>
                        <option value="+81">+81</option>
                        <option value="+7">+7</option>
                        <option value="+82">+82</option>
                      </select>

                      <input
                        type="tel"
                        autoComplete="phone number"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        required
                        pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                        className="rounded-xl p-[10px] outline-none placeholder:text-sky-950 text-[14px] font-[poppins] w-full"
                        placeholder="Your Number*"
                      />
                    </div>
                    <input
                      type="text"
                      name="event"
                      autoComplete="event"
                      value={formData.event}
                      onChange={handleChange}
                      className=" rounded-xl w-full p-[10px] outline-none placeholder:text-sky-950 text-[14px] font-[poppins]"
                      placeholder="Event"
                    />
                    <DatePicker
                      selected={formData.happeningDate}
                      onChange={handleDateChange}
                      className=" rounded-xl p-[10px] outline-none placeholder:text-sky-950 text-[14px] font-[poppins] w-full font-normal"
                      dateFormat="dd/MM/yyyy"
                      id="happeningDate"
                      name="happeningDate"
                      autoComplete="happeningDate"
                      placeholderText="Happening Date"
                    />
                  </div>

                  <div className="flex justify-center items-center mt-[20px]">
                    <button
                      type="submit"
                      className="bg-black hover:rounded-[20px] font-[poppins] text-white pl-[15px] pr-[15px] text-center mt-[20px] w-[250px] pt-[10px] pb-[10px] text-[14px] rounded-xl "
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              {showMessage && (
                <div className="w-full font-[poppins] flex justify-center items-center text-[#A4a4a4] font-semibold text-[16px]">
                  Form submitted successfully...
                </div>
              )}
            </div>
          </section>
        </div>

        <div className="xl:hidden block">
          <div className="hero2">
            <div className="flex flex-col font-bold text-white absolute left-[10px] w-[300px]">
              <p className="text-[40px] font-[season]">
                Looking for Budget Wedding Photography ?
              </p>
              <p className="text-[18px] mt-[30px] font-[poppins]">
                Capture memories on a budget — Artisan photography made it
                simple!
              </p>
            </div>
          </div>
        </div>

        <div ref={blogRef} className="mt-[30px] pl-[15px] pr-[15px]">
          <p className="capitalize font-[season] xl:hidden block text-[35px] font-bold text-center xl:mt-[50px] md:mt-[70px] ">
            Connect With Us
          </p>
          <p className="mt-[20px] xl:hidden block capitalize text-[16px] font-[poppins] text-center  xl:pl-[20px] xl:pr-[20px] mb-[20px]">
            Connect with us to gain expert insights and personalized guidance
            tailored to your needs.
          </p>

          <div className="rounded-2xl xl:px-[80px] xl:py-[30px] px-[30px] py-[25px] border-[1px] border-black mb-[30px] xl:hidden block w-full h-[400px] md:w-full md:h-full">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-[20px]">
                <input
                  type="text"
                  name="name"
                  autoComplete="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="border-b-[1px] font-[poppins] rounded-xl placeholder:text-sky-950 w-full p-[10px] outline-none text-[16px]"
                  placeholder="Name*"
                />

                <div className="flex gap-[20px]">
                  <label htmlFor="countryCode" className="sr-only">
                    Country Code:
                  </label>
                  <select
                    id="countryCode"
                    name="countryCode"
                    autoComplete="country code"
                    value={formData.countryCode}
                    onChange={handleChange}
                    className="border-b-[1px] font-[poppins] rounded-xl placeholder:text-sky-950 p-[10px] outline-nonetext-[16px] w-[100px]"
                  >
                    <option value="+91">+91</option>
                    <option value="+1">+1</option>
                    <option value="+44">+44</option>
                    <option value="+61">+61</option>
                    <option value="+86">+86</option>
                    <option value="+33">+33</option>
                    <option value="+49">+49</option>
                    <option value="+81">+81</option>
                    <option value="+7">+7</option>
                    <option value="+82">+82</option>
                  </select>

                  <input
                    type="tel"
                    autoComplete="phone number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                    pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                    className="border-b-[1px] placeholder:text-sky-950 font-[poppins] rounded-xl p-[10px] outline-none text-[16px] w-full"
                    placeholder="Your Number*"
                  />
                </div>
                <input
                  type="text"
                  name="event"
                  autoComplete="event"
                  value={formData.event}
                  onChange={handleChange}
                  className="border-b-[1px] font-[poppins] rounded-xl w-full p-[10px] outline-none  text-[16px] placeholder:text-sky-950 "
                  placeholder="Event"
                />
                <DatePicker
                  selected={formData.happeningDate}
                  onChange={handleDateChange}
                  className="border-b-[1px] font-[poppins] rounded-xl p-[10px] outline-none  text-[16px] w-full  font-normal placeholder:text-sky-950"
                  dateFormat="dd/MM/yyyy"
                  id="happeningDate"
                  name="happeningDate"
                  autoComplete="happeningDate"
                  placeholderText="Happening Date"
                />
              </div>

              <div className="flex justify-center items-center mt-[30px]">
                <button
                  type="submit"
                  className="bg-black font-[poppins] text-white pl-[15px] pr-[15px] text-center mt-[20px] w-[250px] pt-[10px] pb-[10px] text-[16px] rounded-xl "
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          {showMessage && (
            <div className="w-full flex font-[poppins] justify-center items-center text-[#A4a4a4] font-semibold text-[16px]">
              Form submitted successfully...
            </div>
          )}
        </div>

        <p className="xl:text-[40px] text-[30px] font-[season] font-bold text-center xl:pl-[150px] xl:pr-[150px] pl-[15px]  pr-[15px] mt-[30px] capitalize">
          trusted brand for quality and reliability
        </p>
        <p className="text-[14px] font-[poppins] xl:text-center text-start xl:pl-[150px] xl:pr-[150px] pl-[15px] pr-[15px] text-[#A4A4A4] mt-[30px]">
          Find top-quality, reliable products with our trusted brand, perfect
          for budget-savvy clients. Get excellent photography at great prices,
          ensuring you the best value. Trust us for outstanding products that
          fit your budget.
        </p>

        <div className="xl:flex xl:flex-row xl:relative xl:justify-center gap-[20px] xl:mt-[100px] xl:mb-[70px] hidden ">
          <div className="flex flex-col gap-[20px] w-[200px]">
            <p className="font-bold font-[poppins] text-[50px]">
              {" "}
              {Math.floor(count).toLocaleString()} +
            </p>
            <p className="font-bold font-[season] text-[30px] text-[#A4A4A4]">
              Projects Done
            </p>
            <p className="font-[poppins] text-[14px]">
              We have done 1250+ undertakings till now, we are glad to impart
              this news to you. Get together with us and take your wedding to
              the next even out.
            </p>
          </div>
          <div className="flex gap-[200px]">
            <img
              src={g4}
              alt="data1"
              className="w-[250px] h-[350px] rounded-full object-cover border-[15px] border-white"
            />
            <img
              src={g5}
              alt="data1"
              className="w-[250px] h-[350px] rounded-full object-cover border-[15px] border-white"
            />
            <img
              src={g6}
              className="w-[350px] h-[450px] rounded-full object-cover absolute -bottom-[40px] left-[38%] border-[15px] border-white hover:scale-105"
            />
          </div>
          <div className="w-[200px] flex flex-col gap-[20px]">
            <p className="font-bold font-[poppins] text-[50px]">
              {" "}
              {Math.floor(secondcount).toLocaleString()} +
            </p>
            <p className="font-bold font-[season] text-[30px] text-[#A4A4A4]">
              Destinations
            </p>
            <p className="font-[poppins] text-[14px]">
              We're one of the Most mind-blowing exotic marriage picture takers
              in Bangalore, having gone for various cheerful couples.
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-[20px] w-full xl:hidden pl-[15px] pr-[15px] mt-[30px]">
          <p className="font-bold font-[poppins] text-[50px]">
            {" "}
            {Math.floor(count).toLocaleString()} +
          </p>
          <p className="font-bold font-[season] text-[30px] text-[#A4A4A4]">
            Projects Done
          </p>
          <p className="font-[poppins] text-[14px]">
            We have done 1250+ undertakings till now, we are glad to impart this
            news to you. Get together with us and take your wedding to the next
            even out.
          </p>
        </div>

        <div className="flex justify-center xl:hidden pl-[15px] pr-[15px] mt-[40px] mb-[50px]">
          <div className="flex relative">
            <img
              src={g6}
              alt="wedding data1"
              className="w-full h-[450px] rounded-full border-[15px] border-white object-cover"
            />
            <img
              src={g4}
              alt="wedding data2"
              className="w-[150px] h-[200px] rounded-full border-[15px] border-white object-cover absolute right-0 -bottom-[50px] "
            />
          </div>
        </div>
        <div className="w-full flex flex-col gap-[20px] mb-[30px] pl-[15px] pr-[15px] xl:hidden">
          <p className="font-bold font-[poppins] text-[50px]">
            {" "}
            {Math.floor(secondcount).toLocaleString()} +
          </p>
          <p className="font-bold font-[season] text-[30px] text-[#A4A4A4]">
            Destinations
          </p>
          <p className="font-[poppins] text-[14px]">
            We're one of the Most mind-blowing exotic marriage picture takers in
            Bangalore, having gone for various cheerful couples.
          </p>
        </div>
        <div className="xl:flex xl:flex-row xl:justify-center xl:gap-[40px] xl:pl-[25px] xl:pr-[25px] pl-[15px] pr-[15px] flex flex-col gap-[20px]">
          <div className="xl:w-[300px] w-full flex flex-col">
            <p className="paragraph font-[poppins]">
              <span className="font-[season]">600</span>+{" "}
              <span className="font-[season]">Weddings</span>
            </p>
            <p className="text-[14px] font-[poppins] text-[#A4A4a4] mt-[20px]">
              With over 500 weddings beautifully captured, we bring unparalleled
              expertise and dedication to every celebration. Our experience
              ensures your special day is flawlessly documented and cherished
              forever.
            </p>
          </div>
          <div className="xl:w-[300px] w-full flex flex-col">
            <p className="paragraph font-[poppins]">
              <span className="font-[season]">100</span>+{" "}
              <span className="font-[season]">Designs</span>
            </p>
            <p className="text-[14px] font-[poppins] text-[#A4A4a4] mt-[20px]">
              With over 10,000 designs crafted, we offer a vast array of
              creative and personalized options. Each design reflects our
              commitment to quality and innovation, ensuring your vision comes
              to life.
            </p>
          </div>
          <div className="xl:w-[300px] w-full flex flex-col">
            <p className="paragraph font-[poppins]">
              <span className="font-[season]">2</span>+{" "}
              <span className="font-[season]">Awards</span>
            </p>
            <p className="text-[14px] font-[poppins] text-[#A4A4a4] mt-[20px]">
              Recognized for excellence with over 10 prestigious awards, our
              work stands as a testament to our commitment to quality and
              creativity. Each accolade highlights our dedication to making your
              moments unforgettable.
            </p>
          </div>
          <div className="xl:w-[300px] w-full flex flex-col">
            <p className="paragraph font-[poppins]">
              <span className="font-[season]">100</span>%
              <span className="font-[season]"> Quality</span>
            </p>
            <p className="text-[14px] font-[poppins] text-[#A4A4a4] mt-[20px]">
              Committed to delivering 100% quality, we ensure every detail is
              perfect and exceeds your expectations. Our meticulous attention to
              detail guarantees that your memories are captured with excellence
              and care.
            </p>
          </div>
        </div>

        <p className="xl:text-[40px] font-[season] text-[30px] mt-[30px] font-bold text-center capitalize pl-[15px] pr-[15px] xl:pl-[150px] xl:pr-[150px]">
          Wonders captured with the ravishing lens
        </p>

        <p className="mt-[30px] pl-[15px] pr-[15px] xl:pl-[150px] xl:pr-[150px] text-[14px] font-[poppins] xl:text-center text-start text-[#A4A4A4]">
          Step into a world of wonder with our stunning photography, where your
          best moments are captured in timeless quality. Discover the magic of
          professional service, where every detail is carefully crafted, all at
          a price you'll love.
        </p>
        <div className="xl:flex xl:flex-row xl:justify-center hidden mt-[30px]">
          <div className="grid grid-cols-5 gap-[0.8px]">
            <img
              src={grid1}
              alt="grid items"
              className="xl:w-[300px] w-full h-[300px] object-cover hover:scale-110 "
            />
            <img
              src={grid2}
              alt="grid items"
              className="xl:w-[300px] w-full h-[300px] object-cover hover:scale-110 "
            />
            <img
              src={grid3}
              alt="grid items"
              className="xl:w-[300px] w-full h-[300px] object-cover hover:scale-110 "
            />
            <img
              src={grid4}
              alt="grid items"
              className="xl:w-[300px] w-full h-[300px] object-cover hover:scale-110 "
            />
            <img
              src={grid5}
              alt="grid items"
              className="xl:w-[300px] w-full h-[300px] object-cover hover:scale-110 "
            />
            <img
              src={grid6}
              alt="grid items"
              className="xl:w-[300px] w-full h-[300px] object-cover hover:scale-110 "
            />
            <img
              src={grid7}
              alt="grid items"
              className="xl:w-[300px] w-full h-[300px] object-cover hover:scale-110 "
            />
            <img
              src={grid8}
              alt="grid items"
              className="xl:w-[300px] w-full h-[300px] object-cover hover:scale-110 "
            />
            <img
              src={grid9}
              alt="grid items"
              className="xl:w-[300px] w-full h-[300px] object-cover hover:scale-110 "
            />
            <img
              src={grid10}
              alt="grid items"
              className="xl:w-[300px] w-full h-[300px] object-cover hover:scale-110 "
            />
            <img
              src={grid11}
              alt="grid items"
              className="xl:w-[300px] w-full h-[300px] object-cover hover:scale-110 "
            />
            <img
              src={grid12}
              alt="grid items"
              className="xl:w-[300px] w-full h-[300px] object-cover hover:scale-110 "
            />
            <img
              src={grid16}
              alt="grid items"
              className="xl:w-[300px] w-full h-[300px] object-cover hover:scale-110 "
            />
            <img
              src={grid14}
              alt="grid items"
              className="xl:w-[300px] w-full h-[300px] object-cover hover:scale-110 "
            />
            <img
              src={grid15}
              alt="grid items"
              className="xl:w-[300px] w-full h-[300px] object-cover hover:scale-110 "
            />
          </div>
        </div>

        <div className="grid grid-cols-2 xl:hidden mt-[20px] ">
          <img
            src={grid1}
            alt="grid items"
            className="xl:w-[300px] w-full h-full object-cover hover:scale-110 p-[0.5px]"
          />
          <img
            src={grid2}
            alt="grid items"
            className="xl:w-[300px] w-full h-full object-cover hover:scale-110 p-[0.5px]"
          />
          <img
            src={grid3}
            alt="grid items"
            className="xl:w-[300px] w-full h-full object-cover hover:scale-110 p-[0.5px]"
          />
          <img
            src={grid4}
            alt="grid items"
            className="xl:w-[300px] w-full h-full object-cover hover:scale-110 p-[0.5px]"
          />
          <img
            src={grid5}
            alt="grid items"
            className="xl:w-[300px] w-full h-full object-cover hover:scale-110 p-[0.5px]"
          />
          <img
            src={grid6}
            alt="grid items"
            className="xl:w-[300px] w-full h-full object-cover hover:scale-110 p-[0.5px]"
          />
          <img
            src={grid7}
            alt="grid items"
            className="xl:w-[300px] w-full h-full object-cover hover:scale-110 p-[0.5px]"
          />
          <img
            src={grid8}
            alt="grid items"
            className="xl:w-[300px] w-full h-full object-cover hover:scale-110 p-[0.5px]"
          />
          <img
            src={grid9}
            alt="grid items"
            className="xl:w-[300px] w-full h-full object-cover hover:scale-110 p-[0.5px]"
          />
          <img
            src={grid10}
            alt="grid items"
            className="xl:w-[300px] w-full h-full object-cover hover:scale-110 p-[0.5px]"
          />
          <img
            src={grid11}
            alt="grid items"
            className="xl:w-[300px] w-full h-full object-cover hover:scale-110 p-[0.5px]"
          />
          <img
            src={grid12}
            alt="grid items"
            className="xl:w-[300px] w-full h-full object-cover hover:scale-110 p-[0.5px]"
          />
          <img
            src={grid13}
            alt="grid items"
            className="xl:w-[300px] w-full h-full object-cover hover:scale-110 p-[0.5px]"
          />
          <img
            src={grid14}
            alt="grid items"
            className="xl:w-[300px] w-full h-full object-cover hover:scale-110 p-[0.5px]"
          />
          <img
            src={grid15}
            alt="grid items"
            className="xl:w-[300px] w-full h-full object-cover hover:scale-110 p-[0.5px]"
          />
          <img
            src={grid16}
            alt="grid items"
            className="xl:w-[300px] w-full h-full object-cover hover:scale-110 p-[0.5px]"
          />
        </div>

        <p className="capitalize xl:text-[40px] font-[season] text-[30px] mt-[30px] font-bold pl-[15px] pr-[15px] xl:pl-[150px] xl:pr-[150px] text-center">
          Customized options for any budget or requirement
        </p>

        <p className="mt-[30px] pl-[15px] font-[poppins] pr-[15px] xl:pl-[150px] xl:pr-[150px] xl:text-center text-start text-[14px] text-[#A4A4A4]">
          Personalized choices to suit any budget or preference. Find your
          perfect fit with options tailored just for you.
        </p>

        <div className="xl:flex xl:flex-row xl:justify-center xl:gap-[20px] xl:items-center mt-[40px] xl:pl-[150px] xl:pr-[150px] hidden">
          {displayCardData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center w-full px-[20px] py-[20px] rounded-2xl shadow-lg gap-[20px] pl-[15px] pr-[15px]"
            >
              <img
                src={item.imagesrc}
                alt="carddata"
                className="w-full h-[250px] object-cover rounded-[20px]"
              />
              <p className="xl:text-[30px] text-[25px] font-bold capitalize font-[poppins]">
                {item.title1}
              </p>
              <p className="xl:text-[30px] text-[25px] font-bold capitalize font-[poppins]">
                {item.title}
              </p>
              <p className="text-[14px] font-[poppins] text-[#A4A4a4]">
                {item.description}
              </p>
              <button
                onClick={scrollToForm}
                className="bg-black text-[14px] font-[poppins] hover:rounded-[25px] text-white font-semibold w-[200px] p-[15px] rounded-[20px] text-center"
              >
                Get free Quote
              </button>
            </div>
          ))}
        </div>
        <div className="xl:hidden flex flex-col gap-[20px] mt-[40px] xl:pl-[150px] xl:pr-[150px] pl-[15px] pr-[15px]">
          {displayCardData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center w-full px-[20px] py-[20px] rounded-2xl shadow-lg gap-[20px]"
            >
              <img
                src={item.imagesrc}
                alt="carddata"
                className="w-full h-[250px] object-cover rounded-[20px]"
              />
              <p className="xl:text-[30px] font-[poppins] text-[25px] font-bold capitalize">
                {item.title1}
              </p>
              <p className="xl:text-[30px] font-[poppins] text-[25px] font-bold capitalize">
                {item.title}
              </p>
              <p className="text-[14px] text-[#A4A4a4] font-[poppins]">
                {item.description}
              </p>
              <button
                onClick={scrollToBlog}
                className="bg-black hover:rounded-[25px] font-[poppins] text-[14px] text-white font-semibold w-[200px] p-[15px] rounded-[20px] text-center"
              >
                Get free Quote
              </button>
            </div>
          ))}
        </div>

        <p className="pl-[15px] pr-[15px] xl:pl-[150px] xl:pr-[150px] text-center font-bold xl:text-[40px] font-[season] text-[30px] mt-[30px]">
          Why Artisan ?
        </p>

        <p className="pl-[15px] pr-[15px] xl:pl-[150px] xl:pr-[150px] text-center text-[18px] mt-[30px] text-[#A4a4A4] font-[poppins] font-bold capitalize">
          Why to Choose Artisan : Our Unbeatable Advantages{" "}
        </p>
        <div className="mt-[30px] xl:flex xl:flex-row xl:justify-center flex flex-col gap-[15px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          <div className="flex flex-col items-center rounded-2xl p-[10px] gap-[15px] xl:w-[230px] w-full h-[200px] xl:shadow-lg shadow-md">
            <FaAward size={26} className="mt-[20px]" />
            <p className="text-[20px] font-bold font-[poppins]">Expertise</p>
            <p className="xl:text-[12px] text-[14px] text-[#A4a4a4] font-[poppins]">
              With 10 years of experience and a passion for perfection, we bring
              unparalleled skill to every event.
            </p>
          </div>

          <div className="flex flex-col items-center  rounded-2xl p-[10px] gap-[15px] xl:w-[230px] w-full h-[200px] xl:shadow-lg shadow-md">
            <MdMiscellaneousServices size={26} className="mt-[20px]" />
            <p className="text-[20px] font-bold font-[poppins] ">Service</p>
            <p className="xl:text-[12px] text-[14px] text-[#A4a4a4] font-[poppins]">
              We prioritize your vision, tailoring every detail to reflect your
              unique style and preferences.
            </p>
          </div>

          <div className="flex flex-col items-center  rounded-2xl p-[10px] gap-[15px] xl:w-[230px] w-full h-[200px] xl:shadow-lg shadow-md">
            <MdWorkspacePremium size={26} className="mt-[20px]" />
            <p className="text-[20px] font-bold font-[poppins]">Quality</p>
            <p className="xl:text-[12px] text-[14px] text-[#A4a4a4] font-[poppins]">
              From exquisite decorations to flawless execution, we guarantee
              top-tier results that exceed expectations.nt.
            </p>
          </div>

          <div className="flex flex-col items-center  rounded-2xl p-[10px] gap-[15px] xl:w-[230px] w-full h-[200px] xl:shadow-lg shadow-md">
            <FaSackDollar size={26} className="mt-[20px]" />
            <p className="text-[20px] font-bold font-[poppins]">
              Affordability
            </p>
            <p className="xl:text-[12px] text-[14px] text-[#A4a4a4] font-[poppins]">
              Enjoy premium service without breaking the bank, thanks to our
              competitive pricing and flexible packages.
            </p>
          </div>

          <div className="flex flex-col items-center  rounded-2xl p-[10px] gap-[15px] xl:w-[230px] w-full h-[200px]  xl:shadow-lg shadow-md">
            <BiSolidHappyHeartEyes size={26} className="mt-[20px]" />
            <p className="text-[20px] font-bold font-[poppins] ">
              Client Satisfaction
            </p>
            <p className="xl:text-[12px] text-[14px] text-[#A4a4a4] font-[poppins]">
              Our track record of delighted couples speaks volumes—trust us to
              make your special day truly unforgettable.
            </p>
          </div>
        </div>

        <p className="text-[20px] font-[poppins] font-semibold pl-[15px] pr-[15px] xl:pl-[150px] xl:pr-[150px] mt-[40px] text-center">
          How does it works ?
        </p>
        <p className="mt-[30px] text-[14px] font-[poppins] text-center text-[#A4a4A4] pl-[15px] pr-[15px] xl:pl-[150px] xl:pr-[150px]">
          Planning your dream wedding has never been easier. Follow our five
          simple steps to ensure a seamless and joyful celebration. Let us
          handle the details while you enjoy every magical moment.
        </p>
        <p className="xl:text-[40px] text-[30px] font-[season] font-bold mt-[30px] text-center pl-[15px] pr-[15px] xl:pl-[150px] xl:pr-[150px] ">
          5 Simple Steps to a Stress Free Wedding
        </p>
        <div className="xl:flex xl:flex-row  flex flex-col gap-[10px] xl:justify-center mt-[30px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          {displayStepsData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col xl:shadow-lg shadow-md items-center gap-[10px] rounded-2xl xl:w-[200px] w-full h-[150px] p-[20px]"
            >
              <p className="xl:text-[14px] text-[20px] font-bold mt-[20px] font-[poppins]">
                {item.title}
              </p>
              <p className="xl:text-[12px] text-[14px] text-[#A4a4a4] font-[poppins]">
                {item.description}
              </p>
            </div>
          ))}
        </div>
        <div className="xl:flex xl:flex-row xl:justify-center xl:pl-[150px] xl:pr-[150px] pl-[15px] pr-[15px] mt-[30px] hidden">
          <button
            onClick={scrollToForm}
            className="hover:rounded-[25px] text-[14px] font-[poppins] text-center text-white font-semibold w-[200px] p-[15px] rounded-[20px] bg-[black]  "
          >
            Book Us Now
          </button>
        </div>
        <div className="flex justify-center text-[14px] xl:pl-[150px] xl:pr-[150px] pl-[15px] pr-[15px] mt-[30px] xl:hidden">
          <button
            onClick={scrollToBlog}
            className="hover:rounded-[25px] text-center font-[poppins] text-white font-semibold w-[200px] p-[15px] rounded-[20px] bg-[black]  "
          >
            Book Us Now
          </button>
        </div>
        <p className="xl:text-[40px] text-[30px] font-[season] font-bold pl-[15px] pr-[15px] xl:pl-[150px] xl:pr-[150px] mt-[30px] text-center">
          Voices out of Satisfaction
        </p>
        <p className="mt-[30px] xl:text-center text-start font-[poppins]  pl-[15px] pr-[15px] xl:pl-[150px] xl:pr-[150px] text-[14px] text-[#A4A4A4]">
          {" "}
          Hear from our delighted clients who experienced the magic of our
          exceptional service. Their heartfelt testimonials reflect our
          dedication to making every event unforgettable.
        </p>
        <div className="mt-[30px] xl:flex xl:flex-row xl:justify-center xl:gap-[20px]  hidden">
          {displayTestimonialData.map((item, index) => (
            <div
              key={index}
              className="xl:w-[600px] w-full p-[10px] h-[300px] flex xl:p-[40px] shadow-lg gap-[40px] rounded-2xl "
            >
              <img
                src={item.imgsrc}
                alt="testimonials data"
                className="w-[150px] h-[150px] border-[5px] border-white object-cover rounded-tl-full rounded-bl-full rounded-br-full"
              />
              <div className="flex flex-col xl:gap-[15px] xl:w-[350px] w-full">
                <p className="text-[14px] text-[#A4a4A4] font-[poppins]">
                  {item.description1}
                </p>
                <p className="text-[14px] text-[#A4a4A4] font-[poppins]">
                  {item.description2}
                </p>
                <p className="text-[18px] font-bold font-[poppins] ">
                  {item.name}
                </p>
                <p className="text-[25px] text-[#FFDF00]">★★★★★</p>
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-col mt-[30px] xl:hidden gap-[20px] pl-[15px] pr-[15px]">
          {displayTestimonialData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col gap-[15px] items-center p-[20px] shadow-md rounded-2xl"
            >
              <img
                src={item.imgsrc}
                alt="testimonials data"
                className="w-[150px] h-[150px] border-[5px] border-white object-cover rounded-tl-full rounded-bl-full rounded-br-full"
              />
              <p className="text-[14px] text-[#A4a4A4] font-[poppins]">
                {item.description1}
              </p>
              <p className="text-[14px] text-[#A4a4A4] font-[poppins]">
                {item.description2}
              </p>
              <p className="text-[18px] font-bold font-[poppins]  ">
                {item.name}
              </p>
              <p className="text-[25px] text-[#FFDF00]">★★★★★</p>
            </div>
          ))}
        </div>

        <div className="mt-[20px] flex flex-col items-center gap-[10px] xl:hidden font-bold text-[25px] mb-[20px] pl-[15px] pr-[15px]">
          <p>Call for free consultation at</p>
          <div>
            <Call phoneNumber="+91 9739003636" />
          </div>
        </div>
        <div className="chat-icon">
          <DesktopWa />
        </div>
      </div>
    </>
  );
}
