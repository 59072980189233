import { IoIosChatboxes } from "react-icons/io";

const DesktopWa = () => {
  const handleWhatsAppClick = () => {
    // Add your WhatsApp number here
    const phoneNumber = "9739003636";

    const defaultText = encodeURIComponent(
      "Hi, I have gone through your website.Could you provide me more details."
    );
    // Generate the WhatsApp chat URL
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${defaultText}`;
    // Open the WhatsApp chat link in a new tab
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div>
      <a href="#" onClick={handleWhatsAppClick}>
        <button
          className="bg-black rounded-full pl-[10px] pr-[10px] pt-[10px] pb-[10px] hover:scale-110"
          aria-label="whatsapp"
        >
          <IoIosChatboxes size={32} className="text-white" />
        </button>
      </a>
    </div>
  );
};

export default DesktopWa;
