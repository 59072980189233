import { BiLogoInstagramAlt } from "react-icons/bi";
import { TiSocialFacebook } from "react-icons/ti";
import { AiFillYoutube } from "react-icons/ai";
import logo from "../assets/logos/New png - Artisan white.png";

export default function Footer() {
  return (
    <div className="w-full bg-black xl:p-[30px] p-[20px] xl:flex xl:flex-row xl:justify-center xl:gap-[100px] flex flex-col items-start gap-[30px]">
      <div className="flex flex-col gap-[20px] xl:w-[200px] w-full">
        <img
          src={logo}
          alt="footer logo"
          className="w-[100px] h-[100px] object-cover"
        />
        <p className="text-[14px] text-white font-[poppins]">
          Leading event management company in Bangalore, crafting unforgettable
          experiences that fit your budget perfectly.
        </p>
      </div>
      <div className="flex flex-col gap-[10px] xl:w-[200px] w-full">
        <p className="uppercase text-white font-bold text-[16px] font-[poppins]">
          Quick Links
        </p>
        <a href="/">
          <p className="text-white text-[14px]">Home</p>
        </a>
        <a href="/services">
          <p className="text-white text-[14px]">Services</p>
        </a>
        <a href="/gallery">
          <p className="text-white text-[14px]">Gallery</p>
        </a>
        <a href="/contact">
          <p className="text-white text-[14px]">Contact</p>
        </a>
      </div>
      <div className="flex flex-col gap-[10px] xl:w-[200px] w-full">
        <p className="uppercase text-white font-bold text-[16px] font-[poppins]">
          CONTACT
        </p>
        <p className="text-[14px] text-white font-[poppins]">+91 9739003636</p>
        <p className="uppercase text-white font-bold text-[16px] font-[poppins]">
          INQUIRIES
        </p>
        <a href="mailto:artisanphotographyblr@gmail.com">
          {" "}
          <p className="text-[14px] text-white font-[poppins]">
            artisanphotographyblr@gmail.com
          </p>
        </a>
        <p className="uppercase text-white font-bold text-[16px] font-[poppins]">
          CAREERS
        </p>
        <a href="mailto:hr@happiestorie.com">
          {" "}
          <p className="text-[14px] text-white font-[poppins]">
            hr@happiestorie.com
          </p>
        </a>
      </div>
      <div className="flex flex-col gap-[30px] xl:max-w-[200px] w-full">
        <div className="flex gap-[15px]">
          <a
            href="https://www.instagram.com/artisanphotography.co/"
            aria-label="Read more"
          >
            <BiLogoInstagramAlt
              size={26}
              className="text-white"
              aria-label="button"
            />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100094388984410&sk=about"
            aria-label="Read more"
          >
            <TiSocialFacebook
              size={26}
              className="text-white"
              aria-label="button"
            />
          </a>

          <a
            href="https://youtube.com/@artisan24?si=L-uaaO2lZtGfSZPQ"
            aria-label="Read more"
          >
            <AiFillYoutube
              size={26}
              className="text-white"
              aria-label="button"
            />
          </a>
        </div>
        <div className="flex flex-col gap-[10px]  font-[poppins] text-[14px] text-white">
          <a href="/terms-and-conditions">
            {" "}
            <p>terms & conditions</p>
          </a>
          <a href="/cancellation-and-refund">
            <p>cancellation & refund</p>
          </a>
          <a href="/privacy-policy">
            <p>privacy policy</p>
          </a>
          <p>© 2024 All rights reserved</p>
        </div>
      </div>
    </div>
  );
}
