import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DesktopWa from "../components/DesktopWa";
import Call from "../components/Call";

export default function Contact() {
  const [result, setResult] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    event: "",
    happeningDate: "",
    countryCode: "+91",
    phoneNumber: "",
    city: "",
    budget: "",
    requirements: "",
    eventLocation: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // const handleDateChange = (date) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     happeningDate: date,
  //   }));
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    setShowMessage(true);

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    formDataToSend.append("access_key", "53b106b1-81fa-4d84-ab25-d99e62363861");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formDataToSend,
      });
      const data = await response.json();

      if (data.success) {
        setResult("Form Submitted Successfully");
        setFormData({
          name: "",
          event: "",
          countryCode: "+91",
          phoneNumber: "",
          city: "",
          message: "",
          budget: "",
          requirements: "",
          eventLocation: "",
          happeningDate: "",
        });
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setResult("An error occurred. Please try again later.");
    }

    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  };

  return (
    <>
      <div className="bg-[#f5f5f5] pb-[30px]">
        <div className="xl:block hidden">
          <div className="hero3 ">
            <div className="flex w-[500px] font-[season] text-[60px] font-bold text-white absolute left-0 ">
              Looking To Capture The Memories With Us?
            </div>
          </div>
        </div>

        <div className="xl:hidden block">
          <div className="hero3 ">
            <div className="flex text-[40px] font-[season] font-bold text-white absolute left-0 top-[50%]">
              Looking To Capture The Memories With Us?
            </div>
          </div>
        </div>
        <div className="xl:mt-[40px] mt-[30px] xl:flex xl:flex-row xl:justify-center xl:gap-[60px]  xl:pl-[25px] xl:pr-[25px] flex-col pl-[15px] pr-[15px]">
          <div className="flex flex-col xl:w-[500px] w-full xl:pl-0 xl:pr-0  ">
            <p className="text-[25px] font-bold xl:text-start text-center font-[season]">
              CONTACT{" "}
            </p>
            <p className="text-[14px] text-[#a4a4A4] mt-[20px] font-[poppins]">
              Please provide as much details as possible for us to create a
              customised package for you.
            </p>
            <p className="text-[14px] text-[#a4a4A4] mt-[20px] font-[poppins]">
              Our Photography pricing starts from <span className="font-bold">50,000</span> onwards.
            </p>
            <p className="text-[14px] text-[#a4a4A4] mt-[40px] font-[poppins]">
              Contact us here to schedule date availability followed by a meet
              up consultation to discuss details, packages and more.
            </p>
            <p className="text-[14px] text-[#a4a4A4] mt-[20px] font-[poppins]">
              connect @artisanphotographyblr@gmail.com
            </p>
           
            <p className="text-[14px] text-[#a4a4A4] mt-[20px] font-[poppins]">
              {" "}
              Address: No. 472/7, Balaji Arcade, 2nd Floor, AVS Compound, 80
              Feet Road 4th Block, Ejipura, Koramangala, Bengaluru, Karnataka
              560034
            </p>
            <p className="text-[14px] text-[#a4a4A4] mt-[20px] italic font-[poppins]">
              India : Shravanth +91 9739003636
            </p>
          </div>
          <div className="flex flex-col xl:w-[500px] w-full xl:pl-0 xl:pr-0 xl:mt-0 mt-[30px] ">
            <p className="text-[25px] font-bold mb-[20px] italic self-center font-[season]">
              Get in Touch
            </p>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                autoComplete="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full mt-[10px] font-[poppins] p-[10px] text-[14px] focus:bg-[#f5f5f5] border-b-[1px] border-black bg-[#f5f5f5] outline-none  placeholder:text-black"
                placeholder="Name*"
              />

              <div className="flex gap-[10px]">
                <label htmlFor="countryCode" className="sr-only">
                  Country Code:
                </label>
                <select
                  id="countryCode"
                  name="countryCode"
                  autoComplete="country code"
                  value={formData.countryCode}
                  onChange={handleChange}
                  className="w-[150px] mt-[10px] font-[poppins] p-[10px] text-[14px] focus:bg-[#f5f5f5] border-b-[1px] border-black bg-[#f5f5f5] outline-none placeholder:text-black"
                >
                  <option value="+91">+91</option>
                  <option value="+1">+1</option>
                  <option value="+44">+44</option>
                  <option value="+61">+61</option>
                  <option value="+86">+86</option>
                  <option value="+33">+33</option>
                  <option value="+49">+49</option>
                  <option value="+81">+81</option>
                  <option value="+7">+7</option>
                  <option value="+82">+82</option>
                </select>

                <input
                  type="tel"
                  autoComplete="phone number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                  pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                  className="w-full mt-[10px] p-[10px] font-[poppins] text-[14px] focus:bg-[#f5f5f5] border-b-[1px] border-black bg-[#f5f5f5] outline-none placeholder:text-black"
                  placeholder="Phone number*"
                />
              </div>

              <input
                type="text"
                name="event"
                autoComplete="event"
                value={formData.event}
                onChange={handleChange}
                required
                className="w-full mt-[10px] p-[10px] font-[poppins] text-[14px] focus:bg-[#f5f5f5] border-b-[1px] border-black bg-[#f5f5f5] outline-none placeholder:text-black"
                placeholder="Event*"
              />
              <input
                type="date"
                name="happeningDate"
                autoComplete="happeningDate"
                value={formData.happeningDate}
                onChange={handleChange}
                className="w-full mt-[10px] p-[10px] font-[poppins] text-[14px] focus:bg-[#f5f5f5] border-b-[1px] border-black bg-[#f5f5f5] outline-none placeholder:text-black"
                placeholder="Happening Date"
              />

              {/* <DatePicker
                selected={formData.happeningDate}
                onChange={handleDateChange}
                className="xl:w-[500px] w-[345px] mt-[10px] p-[10px] text-[14px] border-[1px] outline-none placeholder:text-black"
                dateFormat="dd-MM-yyyy"
                id="happeningDate"
                name="happeningDate"
                autoComplete="happeningDate"
                placeholderText="Happening Date"
            
               
              />
             */}

              <input
                type="text"
                name="city"
                autoComplete="city"
                value={formData.city}
                onChange={handleChange}
                required
                className="w-full mt-[10px] p-[10px] font-[poppins] focus:bg-[#f5f5f5] text-[14px] border-b-[1px] border-black bg-[#f5f5f5] outline-none placeholder:text-black"
                placeholder="City*"
              />
              <input
                type="text"
                name="eventLocation"
                autoComplete="eventLocation"
                value={formData.eventLocation}
                onChange={handleChange}
                required
                className="w-full mt-[10px] p-[10px] font-[poppins] focus:bg-[#f5f5f5] text-[14px] border-b-[1px] border-black bg-[#f5f5f5] outline-none placeholder:text-black"
                placeholder="Event Location*"
              />
              <input
                type="text"
                name="budget"
                autoComplete="budget"
                value={formData.budget}
                onChange={handleChange}
                className="w-full mt-[10px] p-[10px] font-[poppins] text-[14px] focus:bg-[#f5f5f5] border-b-[1px] border-black bg-[#f5f5f5] outline-none placeholder:text-black"
                placeholder="Approx Budget eg: 50,000"
              />
              <textarea
                name="requirements"
                autoComplete="requirements"
                value={formData.requirements}
                onChange={handleChange}
                className="w-full mt-[10px] p-[10px] text-[14px] font-[poppins] focus:bg-[#f5f5f5] border-b-[1px] border-black bg-[#f5f5f5] outline-none placeholder:text-black"
                placeholder="Requirements"
              />
              <textarea
                name="message"
                autoComplete="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full mt-[10px] p-[10px] text-[14px] font-[poppins] border-b-[1px] border-black bg-[#f5f5f5] focus:bg-[#f5f5f5] outline-none placeholder:text-black"
                placeholder="Message"
              />

              <div className="flex justify-center items-center mt-[10px]">
                <button
                  type="submit"
                  className="bg-black text-white font-[poppins] pl-[15px] pr-[15px] text-center mt-[20px] w-[250px] pt-[10px] pb-[10px] text-[16px] rounded-xl "
                >
                  Send
                </button>
              </div>
              {showMessage && (
                <div className="w-full flex justify-center font-[poppins] items-center text-[#A4a4a4] font-semibold text-[16px] mt-[20px]">
                  Form submitted successfully...
                </div>
              )}
            </form>
          </div>
        </div>
        <div className="mt-[20px] flex flex-col items-center gap-[10px] xl:hidden font-bold text-[25px] mb-[20px] pl-[15px] pr-[15px]">
          <p >
            Call for free consultation at
          </p>
          <div>
            <Call phoneNumber="+91 9739003636" />
          </div>
        </div>
      </div>
     
      <div className="chat-icon">
        <DesktopWa />
      </div>
    </>
  );
}
