import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import { useEffect } from "react";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CancellationAndRefund from "./pages/CancellationAndRefund";
import {
  HomeMeta,
  ServicesMeta,
  GalleryMeta,
  ContactMeta,
} from "./components/MetaTags";


function ScrollToTopOnRouteChange() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <Navbar />
      <ScrollToTopOnRouteChange />

      <Routes>
        <Route
          path="/"
          element={
            <>
              <HomeMeta />
              <Home />
            </>
          }
        />
        <Route
          path="/gallery"
          element={
            <>
              <GalleryMeta />
             <Gallery/>
            </>
          }
        />
        <Route
          path="/services"
          element={
            <>
              <ServicesMeta />
              <Services />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <ContactMeta />
              <Contact />
            </>
          }
        />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/cancellation-and-refund"
          element={<CancellationAndRefund />}
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
