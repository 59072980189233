import { Helmet } from "react-helmet";

export const HomeMeta = () => (
  <Helmet>
    <title>Artisan Photography - Affordable & Budget Photography Services</title>
    <meta
      name="description"
      content="Discover Artisan Photography, offering professional and budget-friendly event photography services. Capture every special moment at weddings, birthdays, corporate events, and more with our experienced photographers."
    />
    <meta
      name="keywords"
      content="event photography, budget photography, affordable event photographer, wedding photography, birthday photography, corporate event photography, Artisan Photography"
    />
  </Helmet>
);

export const ServicesMeta = () => (
  <Helmet>
    <title>Artisan Photography - Our Services</title>
    <meta
      name="description"
      content="Explore the services offered by Artisan Photography. We provide professional and budget-friendly photography services for weddings, birthdays, corporate events, and more. Capture every moment with our experienced photographers."
    />
    <meta
      name="keywords"
      content="Artisan Photography services, event photography, budget photography, wedding photography, birthday photography, corporate event photography, affordable photographers"
    />
  </Helmet>
);
export const GalleryMeta = () => (
  <Helmet>
    <title>Artisan Photography - Our Portfolio</title>
    <meta
      name="description"
      content="Explore the portfolio of Artisan Photography, showcasing our diverse event photography work. See the special moments we've captured at weddings, birthdays, corporate events, and more."
    />
    <meta
      name="keywords"
      content="Artisan Photography portfolio, event photography portfolio, wedding photography, birthday photography, corporate event photography, professional photographers"
    />
  </Helmet>
);

export const ContactMeta = () => (
  <Helmet>
    <title>Artisan Photography - Contact Us</title>
    <meta
      name="description"
      content="Get in touch with Artisan Photography for professional and budget-friendly event photography services. We are available for weddings, birthdays, corporate events, and more across Bangalore and beyond. Let us capture your special moments."
    />
    <meta
      name="keywords"
      content="contact Artisan Photography, event photography contact, budget photography contact, wedding photography contact, birthday photography contact, corporate event photography contact, Bangalore photographers contact"
    />
  </Helmet>
);
